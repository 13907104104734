import lodash from 'lodash'
import { defineStore } from 'pinia'
import { until } from '@vueuse/core'
import { isRef, watch, type Ref } from 'vue'
import { DrupalJsonApiParams } from 'drupal-jsonapi-params'

import { info } from '~/utils/logging'

const { map, pick } = lodash

// @todo: investigate why tag.id (uuid) filter doesn't work
// const DAILY_WORD_APP_UUID = 'b4033bd3-80ff-47b2-a331-2104b23ff358' as const
const DAILY_WORD_APP_TAG = 'Daily Word Application' as const

const AliasKeys = [
  'default',
  'en',
  'es',
  'internal',
  'path',
  'uuid',
  'promotion_destination',
] as const

type AliasKey = (typeof AliasKeys)[number]

export type Promotion = {
  id: string
  type: string
  title: string
  badge: string
  locale: string
  isPublished: boolean
  canonicalDate: Date
  url: string
  aliases: Record<AliasKey, string>
  body?: {
    format?: string
    processed: string
    summary?: string
    trimmed: string
    value: string
  }
}

type FetchParams = {
  offset?: number
  limit?: number
  locale?: string | Ref<string>
}

const fetchPromos = async (args?: FetchParams): Promise<Promotion[]> => {
  const { limit = 50, offset = 0, locale = 'en' } = args || {}

  if (isRef(locale)) {
    await until(locale).toBeTruthy()
  }

  const params = new DrupalJsonApiParams()
    .addFilter('locale', unref(locale))
    .addFilter('type', 'basic')
    .addFilter('tags.name', DAILY_WORD_APP_TAG)
    .addPageLimit(limit)
    .addPageOffset(offset)
    .addSort('created', 'DESC')

  const response = await $fetch(
    `/jsonapi/${unref(locale)}/promotion?${params.getQueryString()}`,
  )

  type Response = typeof response.default
  // type JsonaResponse = Exclude<Response, Extract<Response, Array<Response>>>

  const data = (response as Response).data

  return map(data || [], (_item: Promotion) => {
    const item = pick(_item, [
      'id',
      'type',
      'title',
      'badge',
      'locale',
      'isPublished',
      'canonicalDate',
      'body',
      'aliases',
    ])

    const {
      aliases: { path: url },
    } = item

    return {
      ...item,
      url,
    }
  })
}

export const usePromotionsStore = defineStore('promotions', () => {
  const { locale } = useI18n()

  const { isLoading, state, isReady, execute } = useAsyncState(
    fetchPromos,
    [],
    { immediate: false },
  )

  const refresh = async () => {
    info('[promotions] refreshing promotions')
    return execute(0, { locale })
  }

  watch(locale, refresh)

  refresh()

  return { data: state, promotions: state, isReady, isLoading, refresh }
})
