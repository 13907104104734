<template>
  <template v-if="stack.length">
    <TransitionGroup
      v-show="active.length"
      name="stack"
      tag="ul"
      class="notifications"
    >
      <li
        v-for="(notification, idx) in stack"
        v-show="!dismissed.has(notification)"
        class="item"
        :key="notification.uuid"
        :data-index="idx"
        :data-state="dismissed.has(notification) ? 'pending' : 'active'"
      >
        <div
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :delay="idx * 100"
        >
          <NotificationStackItem
            @dismiss="remove(notification)"
            @confirm="dismiss(notification)"
            @cancel="restore(notification)"
          >
            <NotificationCard
              :data-uuid="notification.uuid"
              :label="notification.label"
              :body="notification.body"
              :read-more="notification.url"
            />
          </NotificationStackItem>
        </div>
      </li>
    </TransitionGroup>
  </template>
</template>

<script lang="ts">
import type { Notification } from '~/server/api/notifications/types'
</script>

<script lang="ts" setup>
const dismissed = ref(new Set<Notification>())

const { data, ...store } = useNotifications()

const active = useArrayFilter(
  data,
  (item: Notification) => !dismissed.value.has(item),
)

const stack = computed(() => [...active.value, ...dismissed.value.values()])

const remove = async (notification: Notification): Promise<void> => {
  dismissed.value.add(notification)
}

const restore = async (notification: Notification): Promise<void> => {
  dismissed.value.delete(notification)
}

const dismiss = async (notification: Notification): Promise<void> => {
  store.dismiss(notification)
}
</script>

<style lang="postcss">
.stack-enter-active,
.stack-leave-active {
  transition: all 0.5s ease !important;
}
.stack-enter-from,
.stack-leave-to {
  opacity: 0 !important;
  transform: translateX(30px) !important;
}
</style>

<style lang="postcss" scoped>
.notifications {
  @apply isolate grid w-full place-content-stretch;

  transform: translateY(1rem);
}

.item {
  @apply w-full list-none overflow-clip opacity-80 transition-all duration-100 ease-in-out;

  grid-column-start: 1;
  grid-row-start: 1;

  transform: translate(0px, -1rem) scale(0.96);
  transform-origin: top center;

  &:nth-child(1) {
    @apply h-fit min-h-[50px] rounded-lg opacity-100 shadow-sm shadow-brand-midnight;

    transform: translate(0px, 0px) scale(1);
    z-index: 2;
  }

  &:nth-child(2) {
    @apply opacity-90;

    transform: translate(0px, -0.5rem) scale(0.98);
    z-index: 1;
  }

  &:nth-child(-n + 3) {
    @apply visible;
  }

  &:not(:nth-child(-n + 1)) {
    @apply max-h-[100px];
  }

  &:not(:nth-child(-n + 3)) {
    @apply invisible;
  }
}
</style>
