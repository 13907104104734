<template>
  <div
    class="callout block h-full w-full bg-brand-marigold bg-opacity-75 bg-cover bg-top bg-no-repeat font-montserrat"
    :class="pending ? 'animate-pulse' : ''"
    :style="{ backgroundImage }"
  >
    <a
      v-if="data"
      class="flex h-full w-full flex-col justify-end"
      :class="{ 'has-image img-link': data.image }"
      :href="
        locale === 'es' ? 'https://www.unity.org/es' : 'https://www.unity.org'
      "
    >
      <h3
        class="bg-gradient-to-t from-slate-900 to-transparent p-4 text-center text-2xl leading-tight tracking-tight text-white"
      >
        {{ t('The Latest on Unity.org') }}
      </h3>
    </a>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()

const IDENTITY_DAILY_WORD = '67cf354d-e82e-4237-b529-fe96d35d1c65'

const props = defineProps<{
  locale: string
}>()

const { data, pending } = useLatestContent({
  resource: '/node/article',
  locale: props.locale,
  identity: IDENTITY_DAILY_WORD,
  imageStyle: 'square',
})

const backgroundImage = computed(() => {
  const { image } = unref(data) || {}
  if (!image) return 'initial'

  const { src } = image

  return `url(${src})`
})
</script>
