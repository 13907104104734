<template>
  <div class="callouts">
    <div class="items">
      <CalloutSpiritualityAndHealthMagazine
        class="item sh-magazine aspect-[3/4]"
        :locale="locale"
      />
      <CalloutUnity class="item latest aspect-square" :locale="locale" />
      <CalloutPromotion class="item promo aspect-square" :locale="locale" />
      <CalloutDailyWordMagazine
        class="item daily-word aspect-[3/4]"
        :locale="locale"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { locale } = useI18n()
</script>

<style lang="postcss" scoped>
.callouts {
  container: callouts / inline-size;
}

.flip {
  transform-style: preserve-3d;
}

.items {
  @apply space-y-4;
}

.item {
  @apply w-full min-w-[160px] break-inside-avoid break-words;

  hyphens: auto;
}

@container callouts (min-width: 320px) {
  .items {
    @apply columns-2;
  }
}

@container callouts (min-width: 200px) {
  .item {
    @apply mx-auto max-w-[240px];
  }
}
</style>
