<template>
  <div class="w-full rounded-lg border border-white bg-brand-sky p-4">
    <div class="space-y-2">
      <header v-if="label">
        <h5
          class="title max-w-[25ch] text-sm font-semibold text-brand-turquoise line-clamp-1"
        >
          {{ label }}
        </h5>
      </header>
      <div
        class="flex flex-col place-content-start font-medium text-brand-midnight"
      >
        <p class="message line-clamp-2">{{ body }}</p>
        <NuxtLink
          v-if="readMore"
          class="btn-ghost btn-sm btn self-end text-brand-turquoise"
          :to="readMore"
        >
          {{ t('Read more') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
type Url = string

const { t } = useI18n()

const props = defineProps<{
  label?: string
  body: string
  readMore?: Url
}>()
</script>
