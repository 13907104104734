<template>
  <div class="bar">
    <nav class="flex flex-row items-center justify-evenly gap-4 px-4">
      <NuxtLink class="item icon-link" :to="localePath('/')">
        <Icon class="logo h-8 w-auto text-4xl" name="UnityLogo" />
      </NuxtLink>
      <div class="item flex place-content-center">
        <span class="brand text-2xl font-medium font-sans text-brand-peacock"
          >BeUnity</span
        >
      </div>
      <NuxtLink
        class="item icon-link justify-end text-3xl"
        :to="localePath('/preferences')"
      >
        <Icon class="icon" name="material-symbols:settings-rounded" />
        <span class="label sr-only">{{ t('Preferences') }}</span>
      </NuxtLink>
    </nav>
  </div>
</template>

<script lang="ts" setup>
const { t, d } = useI18n()
const localePath = useLocalePath()
</script>

<style lang="postcss" scoped>
.item {
  @apply inline-flex flex-auto;
}

.icon-link {
  @apply flex-grow-0;
}

.icon {
  @apply fill-current;
}
</style>
