<template>
  <div
    v-if="pending || data"
    ref="target"
    class="callout block h-full w-full bg-brand-turquoise bg-opacity-75 bg-contain bg-top bg-no-repeat font-montserrat"
    :class="{ 'animate-pulse': pending }"
    :style="{ backgroundImage }"
  >
    <template v-if="data">
      <a
        class="flex h-full w-full flex-col justify-end"
        :class="{ 'has-image img-link': data.image }"
        :href="data.url"
      >
        <h3
          class="bg-gradient-to-t from-slate-900 to-transparent p-4 text-center text-2xl leading-tight tracking-tight text-white"
        >
          {{ t('Spirituality & Health') }}
        </h3>
      </a>
    </template>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()

const IDENTITY_SH_MAGAZINE = '4a5949fc-1503-465f-9073-b66aa4388a70'

const props = defineProps<{
  locale: string
}>()

const { data, pending } = useLatestContent({
  resource: '/node/collection',
  locale: props.locale,
  identity: IDENTITY_SH_MAGAZINE,
})

const backgroundImage = computed(() => {
  const { image } = unref(data) || {}
  if (!image) return 'initial'

  const { src } = image

  return `url(${src})`
})
</script>
