<template>
  <div
    class="relative cursor-wait rounded-sm bg-brand-midnight bg-opacity-90 text-brand-sky dark:bg-brand-sky dark:text-brand-midnight"
    @mouseover="pause"
    @mouseout="resume"
  >
    <slot />
    <div
      class="absolute bottom-0 right-0 h-1 bg-brand-moss opacity-60 transition-all"
      :style="style"
    />
  </div>
</template>

<script lang="ts" setup>
import { reactiveStyle } from '@vueuse/motion'

const props = defineProps<{
  duration: number
}>()

const emit = defineEmits(['done'])

const { state, style } = reactiveStyle({
  width: '50%',
})

let start: number | undefined
let end = props.duration
const { pause: _pause, resume } = useRafFn(({ delta, timestamp }) => {
  if (!start) start = timestamp
  if (start !== timestamp) end -= Math.floor(delta)

  if (end <= 0) return stop()

  const percentage = Math.floor((end / props.duration) * 100)
  state.width = `${percentage}%`
})

const pause = () => {
  _pause()
  start = undefined
}

const stop = () => {
  pause()
  emit('done')
}
</script>
