type Url = string

type ImageResourceMeta = {
  title: string
  alt: string
  height: number
  width: number
  [key: string]: unknown
}

const VARIANTS = {
  original: (data: Partial<ImageResourceMeta>) => ({
    height: 1,
    width: 1,
    ...data,
  }),
  // @todo: finish
  large: (data: Partial<ImageResourceMeta>) => ({
    height: 1,
    width: 1,
    ...data,
  }),
  magazine: ({ height, width, ...data }: Partial<ImageResourceMeta>) => {
    const h = (value?: number) => 800
    const w = (value?: number) => 600

    return {
      ...data,
      height: h(height),
      width: w(width),
    }
  },
  profile: ({ height, width, ...data }: Partial<ImageResourceMeta>) => {
    const h = (value?: number) => 800
    const w = (value?: number) => 600

    return {
      ...data,
      height: h(height),
      width: w(width),
    }
  },
  header: ({ height, width, ...data }: Partial<ImageResourceMeta>) => {
    const h = (value?: number) => 300
    const w = (value?: number) => 1200

    return {
      ...data,
      height: h(height),
      width: w(width),
    }
  },
  app_header: ({ height, width, ...data }: Partial<ImageResourceMeta>) => {
    const h = (value?: number) => 540
    const w = (value?: number) => 900

    return {
      ...data,
      height: h(height),
      width: w(width),
    }
  },
  promotion_shop: ({ height, width, ...data }: Partial<ImageResourceMeta>) => {
    const h = (value?: number) => value
    const w = (value?: number) => value

    return {
      ...data,
      height: h(height),
      width: w(width),
    }
  },
  // square image style resizes the image to 600 x 600
  square: ({ height, width, ...data }: Partial<ImageResourceMeta>) => {
    const h = (value?: number) => 600
    const w = (value?: number) => 600

    return {
      ...data,
      height: h(height),
      width: w(width),
    }
  },
} as const

type Variants = typeof VARIANTS
type Variant = keyof Variants

function getStyledImageOriginal(file: MediaJson['image']) {
  const sizer = VARIANTS.original

  const {
    filemime: type,
    resourceIdObjMeta: { alt, title, height, width },
    uri,
  } = file

  if (!uri) return sizer({ alt, height, width })

  const { url: src } = uri

  if (!src) return sizer({ alt, height, width })
  // const { meta, type } = derivative

  return sizer({
    alt,
    src,
    title,
    height,
    width,
    type,
    // ...meta,
  })
}

type DrupalEntityJson = Record<string, any> & {
  id: string
  type: string
}

type ImageDerivative = {
  href: Url
  title: string
  meta: Record<string, string>
}

export type MediaJson = DrupalEntityJson & {
  id: string
  type: string
  image: DrupalEntityJson & {
    filemime: string
    resourceIdObjMeta: {
      alt: string
      title: string
      height: number
      width: number
      imageDerivatives: {
        links: Record<Variant, ImageDerivative>
      }
    }
  }
}

export function gcd(a: number, b: number): number {
  if (b == 0) return a
  return gcd(b, a % b)
}

export function getStyledImage(media: MediaJson, variant: Variant) {
  const sizer = VARIANTS[variant]

  const defaults = sizer({} as any)

  if (!media) return defaults

  // file entity object
  const file = media?.image
  if (!file) return defaults

  if (variant === 'original') return getStyledImageOriginal(file)

  if (!file.links?.[variant]) return defaults

  const {
    resourceIdObjMeta: {
      alt,
      title,
      height,
      width,
      imageDerivatives: {
        links: { [variant]: derivative },
      },
    },
  } = file

  if (!derivative) return sizer({ alt, height, width })
  const { href, meta } = derivative
  const { pathname, search } = new URL(href)

  return sizer({
    ...meta,
    alt,
    src: `${pathname}${search}`,
    title,
    height,
    width,
  })
}
