import lodash from 'lodash'
import { DrupalJsonApiParams } from 'drupal-jsonapi-params'
import { joinURL, getQuery, withQuery } from 'ufo'
import { gcd, getStyledImage } from '~/utils/styled-image'
// import { join, sep } from 'pathe'

import type { Ref } from 'vue'

const { first, pick } = lodash

type Url = string
type JsonApiResponse<T extends any> = {
  jsonapi?: Record<string, any>
  links?: {
    [key: string]: {
      href: Url
    }
  }
  data: T
  meta?: {
    count: number
  }
}

type Response = JsonApiResponse<any>

const transformResponse = (
  response: JsonApiResponse<any[]>,
  frontendBaseUrl: string,
): Response => {
  if (!response.data) return response
  if (!response.data.length) return { ...response, data: undefined }

  const data = pick(first(response.data), [
    'id',
    'title',
    'displayDate',
    'mainImage',
    'body',
    'type',
    'badge',
    'canonicalDate',
    'aliases',
    'alternateUrl',
  ])

  const alternateUrl = data?.alternateUrl?.url
  const path = alternateUrl || data?.aliases?.default

  if (!path) return { ...response, data }

  const url = new URL(path, frontendBaseUrl).toString()

  return {
    ...response,
    data: { ...data, url },
  }
}

const makeQuery = (locale: string, identity: string) => {
  const query = new DrupalJsonApiParams()
    .addFilter('identity.id', identity)
    .addFilter('locale', locale)
    .addFilter('isPublished', '1')
    .addInclude(['mainImage.image'])
    .addPageLimit(1)
    .addSort('canonicalDate', 'DESC')
    .getQueryString()

  const params = getQuery(`http://localhost?${query}`)

  return params
}

type ImageStyleVariant = Parameters<typeof getStyledImage>[1]

type useLatestContentOptions = {
  resource: string
  locale?: string | Ref<string>
  identity: string
  imageStyle?: ImageStyleVariant
}

export function useLatestContent({
  imageStyle = 'magazine',
  ...opts
}: useLatestContentOptions) {
  const {
    public: { unityOrgUrl },
  } = useRuntimeConfig()

  const path = joinURL('/jsonapi', unref(opts?.locale || 'en'), opts.resource)
  const query = makeQuery(unref(opts.locale || 'en'), unref(opts.identity))

  const { data: response, pending } = useAsyncData(
    withQuery(path, query),
    async () => {
      if (process.dev) {
        console.debug('Fetching latest content', { path, query })
      }

      const resp = await $fetch<Response>(path, { query })

      return resp
    },
    {
      transform: (res) => {
        const transformed = transformResponse(res, unityOrgUrl)

        if (!transformed?.data?.mainImage) return transformed

        const { data } = transformed
        const { mainImage } = data

        const image = mainImage && getStyledImage(mainImage, imageStyle)

        return {
          ...transformed,
          data: { ...data, image },
        }
      },
    },
  )

  const data = computed(() => response.value?.data)

  /*
  const { style } = useElementStyle(opts.target)

  const setBackground = () => {
    const { image } = unref(data) || {}
    if (!image) return

    const { src, height, width } = image

    const ratio = gcd(width, height)
    const aspectRatio = `${width / ratio} / ${height / ratio}`

    style.backgroundImage = `url(${src})`
    // style.aspectRatio = aspectRatio
  }

  // setBackground()

  watchEffect(setBackground)
  */

  return { response, data, pending }
}
