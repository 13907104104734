<template>
  <div class="bar">
    <nav
      class="flex flex-row items-stretch justify-around py-2 md:sticky md:pt-[20vh]"
    >
      <NuxtLink
        v-for="link in links"
        :key="link.key"
        class="icon-link"
        :to="localePath(link.path)"
      >
        <Icon class="icon text-4xl" :name="link.icon" />
        <span class="label">{{ link.label }}</span>
      </NuxtLink>
    </nav>
  </div>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()

const { links } = useNavbar()
</script>

<style lang="postcss" scoped>
.icon-link {
  @apply flex flex-col place-items-center text-center text-xs text-white opacity-[0.65] hover:opacity-100 active:opacity-100;
}

.icon {
  @apply fill-current text-inherit;
}

.label {
  @apply pt-2 pb-0 text-xs uppercase;
}

[aria-current] {
  @apply opacity-100;
}
</style>
